import React, { useCallback } from 'react';
import { Box, Composition } from 'atomic-layout';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { Text, Button } from '@ffn/ui';
import 'styled-components/macro';
import { useTranslation } from 'lib/hooks';

export function Dropzone({ doctypeTitle, document_type, setUploadedFiles }) {
  const { t } = useTranslation();
  const onDropAccepted = useCallback(
    acceptedFiles => {
      const filesState = acceptedFiles.map(file => {
        return {
          document: file,
          id: uuidv4(),
          metadata:
            document_type === 'collection_letter'
              ? {
                  document_type,
                  currentBalance: '',
                  newAccountNumber: '',
                  newCreditorName: '',
                  sameNumberAsOriginal: []
                }
              : document_type && document_type !== 'customer_upload'
              ? { document_type, currentBalance: '' }
              : { document_type }
        };
      });
      setUploadedFiles(filesState);
    },
    [document_type, setUploadedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted
  });

  return (
    <>
      <Composition
        justifyContent="center"
        alignItems="center"
        height="250px"
        gap={16}
        templateCols="75%"
        css={`
          border-radius: 8px;
          background-color: ${props => props.theme.colors.grayLight};
        `}
      >
        <Box
          flex
          flexDirection="column"
          height="100%"
          justifyContent="center"
          alignItems="center"
          padding="0px"
          paddingSm="0px 4%"
          paddingMd="0px 12%"
          paddingLg="0px 16%"
          {...getRootProps({
            className: 'Dropzone'
          })}
        >
          <input {...getInputProps()} />
          <Button
            data-testid="browse-button"
            variant="primary"
            onClick={e => e.preventDefault()}
          >
            {t('docUpload.browse')} {doctypeTitle?.toLowerCase()}
          </Button>
          <Text
            css={`
              margin-bottom: 0;
            `}
          >
            {t('docUpload.or')}
          </Text>
          <Text
            css={`
              margin-bottom: 0;
              text-align: center;
            `}
          >
            {t('docUpload.dragAndDrop')} {doctypeTitle?.toLowerCase()}{' '}
          </Text>
        </Box>
      </Composition>
    </>
  );
}

export default Dropzone;
