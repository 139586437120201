import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLaunchDarkly, useFeatureFlag } from 'lib/hooks';

import { getClient } from 'shared-selectors/client';

const RegionalEligibilityContext = createContext();

const CALIFORNIA = 'CA';

export function RegionalEligibilityProvider({ children }) {
  const [regionalEligibility, setRegionalEligibility] = useState({
    showDocumentVault: false,
    showStatementGeneration: false
  });

  const client = useSelector(getClient);
  const stateOfResidence = client?.clientState;

  const { flags, activeFlagNames } = useLaunchDarkly();
  const { feature, path } = useFeatureFlag();

  useEffect(() => {
    if (flags) {
      setRegionalEligibility({
        showDocumentVault:
          // Launch Darkly Flag
          flags[activeFlagNames?.documentVault] === 'showDocumentVault' &&
          // siteConfig
          path('/document-vault') &&
          // California residency
          stateOfResidence === CALIFORNIA,
        showStatementGeneration:
          // Launch Darkly Flag
          flags[activeFlagNames?.statementGeneration] ===
            'showStatementGeneration' &&
          // siteConfig
          feature('dedicated-account.statement-generation') &&
          // California residency
          stateOfResidence === CALIFORNIA
      });
    }
  }, [
    activeFlagNames.documentVault,
    activeFlagNames.statementGeneration,
    feature,
    flags,
    path,
    stateOfResidence
  ]);

  return (
    <RegionalEligibilityContext.Provider value={regionalEligibility}>
      {children}
    </RegionalEligibilityContext.Provider>
  );
}

export const useRegionalEligibility = () => {
  const context = useContext(RegionalEligibilityContext);
  if (context === undefined) {
    throw new Error(
      'useRegionalEligibility must be used within theRegionalEligibilityContext provider'
    );
  }

  return context;
};

export default useRegionalEligibility;
