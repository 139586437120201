import React, { createContext, useContext, useMemo } from 'react';
import { useSiteData, useDisabledRouteObject } from 'lib/hooks';
import * as R from 'ramda';
import { matchRoutes } from 'react-router-dom';

const FeatureFlagContext = createContext(null);

export const FeatureFlagProvider = ({ children }) => {
  const { features } = useSiteData();
  const disabledRoutesObject = useDisabledRouteObject();

  const featureUtils = useMemo(() => {
    const path = pathKey =>
      R.isNil(pathKey)
        ? true
        : R.isNil(matchRoutes(disabledRoutesObject, pathKey));

    const feature = flagKey =>
      R.isNil(flagKey) ? true : Boolean(R.path(flagKey.split('.'), features));

    const featureFlag = ({ pathKey, flagKey }) =>
      path(pathKey) && feature(flagKey);

    return { path, feature, featureFlag };
  }, [features, disabledRoutesObject]);

  return (
    <FeatureFlagContext.Provider value={featureUtils}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = () => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error('useFeature must be used within a FeatureFlagProvider');
  }
  return context;
};
